import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api'
import { randomAlphanumericString } from '@/utils/string-util'

export const useWorkshopCourseMgmtStore = defineStore('workshopCourseMgmt', {
  state: () => ({
    currentWorkshopCourses: [],
    allWorkshopCourses: [],
    workshopId: ''
  }),
  actions: {
    setCourseMgmtWorkshopId (workshopId) {
      this.workshopId = workshopId
    },
    async fetchCurrentWorkshopCourses (workshopId) {
      const res = await workshopApi.get(`/admin-courses/workshop/${workshopId}`)
      const currentWorkshopCourses = res.data.toSorted((a, b) => a.order - b.order)
      this.currentWorkshopCourses = currentWorkshopCourses
    },
    async fetchAllWorkshopCourses () {
      const res = await workshopApi.get('/admin-courses/all')
      const allWorkshopCourses = res.data.toSorted((a, b) => a.order - b.order)
      Object.assign(this.allWorkshopCourses, allWorkshopCourses)
    },
    async saveWorkshopCourses (courses) {
      const selectedCourses = courses.map(course => {
        return {
          course: { id: course.id },
          workshop: { id: this.workshopId }
        }
      })
      await workshopApi.post('/admin-courses/batch-add', selectedCourses)
      await this.fetchCurrentWorkshopCourses(this.workshopId)
    },
    async deleteWorkshopCourse (selectedCourseId) {
      const selectedCourse = {
        data: {
          id: selectedCourseId
        }
      }
      await workshopApi.delete('/admin-courses', selectedCourse)
      await this.fetchCurrentWorkshopCourses(this.workshopId)
    },
    async addWorkshopDidactic (payload) {
      const selectedCourse = {
        id: payload.selectedCourseId
      }
      await workshopApi.post(`/admin-courses/add-didactic/${payload.didacticId}`, selectedCourse)
      await this.fetchCurrentWorkshopCourses(this.workshopId)
    },
    async removeWorkshopDidactic (payload) {
      const selectedCourse = {
        data: {
          id: payload.selectedCourseId
        }
      }
      await workshopApi.delete(`/admin-courses/remove-didactic/${payload.didacticId}`, selectedCourse)
      await this.fetchCurrentWorkshopCourses(this.workshopId)
    },
    async updateDidacticVersion (payload) {
      const selectedCourse = {
        id: payload.selectedCourseId
      }
      await workshopApi.put(`/admin-courses/update-didactic-version/${payload.newDidacticVersionId}`, selectedCourse)
      await this.fetchCurrentWorkshopCourses(this.workshopId)
    },
    async uploadWorkshopAttachment (payload) {
      const form = new FormData()
      form.append('selectedCourse', new Blob([JSON.stringify(payload.selectedCourse)], { type: 'application/json' }))
      form.append('attachment', payload.attachment)

      await workshopApi.post('/admin-courses/add-attachment', form, {
        headers: { 'Content-Type': `multipart/form-data; boundary=WebKitFormBoundary${randomAlphanumericString()}` }
      })
      await this.fetchCurrentWorkshopCourses(this.workshopId)
    },
    async deleteWorkshopAttachment (payload) {
      const selectedCourse = {
        data: {
          id: payload.selectedCourseId
        }
      }
      await workshopApi.delete(`/admin-courses/remove-attachment/${payload.attachmentId}`, selectedCourse)
      await this.fetchCurrentWorkshopCourses(this.workshopId)
    }
  }
})
