const workshopEndpoints = {
  PREFIX: 'workshop',
  INTERNAL_REGISTER: 'register/internal',
  ATTENDEE_REGISTER: 'register/attendee',
  FACULTY_REGISTER: 'register/faculty',

  LOGIN: 'login',
  LOGOUT: 'logout',
  CREATE: 'create',
  VIEW: 'view',
  EDIT: 'edit',
  ADD: 'add',
  ACTIVE_ATTENDEES: 'active-attendees',
  INACTIVE_ATTENDEES: 'inactive-attendees',
  UPCOMING_WORKSHOPS: 'upcoming-workshops',
  PAST_WORKSHOPS: 'past-workshops',
  ADMIN_ACCOUNTS: 'admin-accounts',
  ADMIN_ADD_USER: 'admin-add-user',
  COURSE_MANAGEMENT: 'course-mgmt',
  MANAGE_ATTENDEES: 'manage-attendees',
  USERS_MGMT: 'users-mgmt',
  USERS_MGMT_FACULTY: 'faculty',
  USERS_MGMT_ATTENDEES: 'attendees',
  MANAGE_ATTENDEES_DETAILS: 'manage-attendees-details',
  ADD_FACULTY: 'add-faculty',
  EDIT_FACULTY: 'edit-faculty',
  EDIT_ATTENDEE: 'edit-attendee',
  EDIT_FACULTY_ATTENDEE: 'edit-faculty-attendee',
  VIEW_USER_DETAILS: 'user-details',
  ATTENDEE_COURSE_PROGRESS: 'attendee-course-progress',
  // Workshop Dashboard
  HOME: 'home',
  PROFILE: 'profile',
  CURRICULAR_HISTORY: 'curricular-history',
  CURRICULAR_HISTORY_ATTENDEE: 'attendee',
  CURRICULAR_HISTORY_FACULTY: 'faculty',
  DETAILS: 'details',
  FACULTY_ATTENDEES: 'attendees',

  // Workshop Course
  COURSE: 'course',
  DIDACTIC: 'didactic'
}

// Need to use the outdated `module.exports` below because we need
// `workshopEndpoints` in `vue.config.js`, which doesn't support `import`
module.exports = workshopEndpoints
