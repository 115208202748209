<template>
  <video
    :key="src"
    ref="video"
    controls
    controlsList="nodownload"
    :poster="poster"
    @contextmenu.prevent
    @timeupdate="$emit('timeupdate', $event)"
    @loadedmetadata="onVideoLoaded"
    @play="$emit('play')"
  >
    <source :src="src" type="video/mp4">
    Sorry, your browser does not support embedded videos.
  </video>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    timeStamp: {
      type: Number,
      required: false
    },
    poster: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['timeupdate', 'videoLoaded', 'play'],
  computed: {
    videoDuration () {
      return this.$refs.video.duration
    }
  },
  methods: {
    onVideoLoaded () {
      if (this.timeStamp) {
        this.$refs.video.currentTime = this.timeStamp
      }
      this.$emit('videoLoaded')
    },
    pause () {
      this.$refs.video.pause()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
