import { defineStore } from 'pinia'
import { videoStreamTypes } from '@/residency/views/video/video-enums'

export const useVideoPlayerStore = defineStore('videoPlayer', {
  state: () => ({
    srcObject: {
      [videoStreamTypes.LAPAROSCOPE]: null,
      [videoStreamTypes.FACECAM]: null
    },
    preselectedLaparoscopeDeviceId: '',
    preselectedFacecamDeviceId: ''
  }),
  getters: {
    srcObjectLaparoscope: state => state.srcObject[videoStreamTypes.LAPAROSCOPE],
    srcObjectFacecam: state => state.srcObject[videoStreamTypes.FACECAM]
  },
  actions: {
    setPreselectedDeviceId ({ deviceId, deviceType }) {
      if (deviceType === videoStreamTypes.LAPAROSCOPE) {
        this.preselectedLaparoscopeDeviceId = deviceId
      } else if (deviceType === videoStreamTypes.FACECAM) {
        this.preselectedFacecamDeviceId = deviceId
      }
    },
    resetPreselectedDeviceId (deviceType) {
      if (deviceType === videoStreamTypes.LAPAROSCOPE) {
        this.preselectedLaparoscopeDeviceId = ''
      } else if (deviceType === videoStreamTypes.FACECAM) {
        this.preselectedFacecamDeviceId = ''
      }
    },
    setSrcObjectForStream ({ streamType, srcObject }) {
      const newSrcObject = this.srcObject
      newSrcObject[streamType] = srcObject
      this.srcObject = newSrcObject
    }
  }
})
